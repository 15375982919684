<template lang="pug">
  v-container
    v-row(justify="center")
      v-col(cols="2")
        v-card
          v-toolbar(
            color="green"
            dense
            dark
            flat
          )
            span Create
          v-container
            create-unit
      v-col(cols="3")
        v-card
          v-toolbar(
            color="primary"
            dense
            dark
            flat
          )
            span List
          v-container
            table-unit
</template>
<script>
export default {
  name: 'Units',
  components: {
    createUnit: () => import('./Create'),
    tableUnit: () => import('./Table'),
  },
}
</script>